/* You can add global styles to this file, and also import other style files
*
* REFERENCE
* https://www.joshwcomeau.com/css/custom-css-reset/
*/
/* @layer base { */
:root {
  --color-frameBgColor: 255 255 255;
  --color-frameBorderColor: 243 244 246;
  --color-primaryButtonColor: 20 111 104;
  --color-skeletonColor: 210, 210, 210;
  --color-buttonBorderColor: 209 213 219;
  --color-secondaryButtonColor: 0 0 0;
  --color-mainTextColor: 0 0 0;
  --color-secondaryTextColor: 156 163 175;
  --color-linkColor: 37 99 235;
  --color-checkMarkColor: 16 185 129;
  --color-warningBgColor: 255 247 237;
  --color-portfolioColor: 247 249 246;
}
/* } */
/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  width: 100%;
}
body.fixed {
  overflow: hidden;
}
/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}
